// src/stage/start_app.tsx

import React, { useEffect } from 'react';
import styled from 'styled-components';
import splashLogo from '../res/logo_splash.png';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px; // Вы можете настроить отступы по своему усмотрению
  box-sizing: border-box;
`;

const Logo = styled.img`
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
`;

const StartApp: React.FC<{ onTimeout: () => void }> = ({ onTimeout }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onTimeout();
    }, 1000);
    return () => clearTimeout(timer);
  }, [onTimeout]);

  return (
    <Container>
      <Logo src={splashLogo} alt="Splash Logo" />
    </Container>
  );
};

export default StartApp;
