import React, { useState } from 'react';
import styled from 'styled-components';
import ProfilePic from '../res/avatar.png';
import FlagIcon from '../res/US.png';
import ArrowLeftProfile from '../res/ArrowLeftProfile.png';

interface ProfileProps {
  onBackClick: () => void;
  profileData: {
    avatar?: string;
    name?: string;
    flag?: string;
    countryCode?: string;
    age?: number;
    followed?: number;
    followers_count?: number;
    following_count?: number;
  };
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
`;

const TopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 20px;
  position: relative;
  margin-bottom: 1.5vh;
`;

const BackButton = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: absolute;
  left: 20px;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  text-align: center;
  flex-grow: 1;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  padding-left: 10px;
`;

const ProfileImage = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 8px;
  margin-right: 10px;
  background-color: #f0f0f0;
`;

const UsernameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Username = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin: 0;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
`;

const Flag = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

const InfoText = styled.span`
  font-size: 18px;
  color: #333;
`;

const StatsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding-top: 10px;
  margin-bottom: 20px;
`;

const Stat = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StatNumber = styled.span`
  font-size: 20px;
  font-weight: bold;
`;

const StatLabel = styled.span`
  font-size: 16px;
  color: #888;
`;

const Section = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const SectionTitle = styled.h3`
  font-size: 35px;
  font-weight: bold;
  margin: 20px 0 20px;
  color: #000;
`;

const SectionItem = styled.div`
  font-size: 20px;
  color: #333;
  margin: 20px 0;
  cursor: pointer;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(220, 220, 220, 0.6);
  margin: 10px 0;
`;

/* Стиль для модальных окон */
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: linear-gradient(165.31deg, #6d33ff 11.36%, #612ce8 37.32%, #0e0033 99.31%);
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  width: 90%;
  max-width: 400px;
`;

const CloseButton = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
`;

const ModalText = styled.div`
  font-size: 18px;
  color: white;
  margin: 20px 0;
`;

const Button = styled.button`
  width: 90%;
  padding: 15px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 25px;
  border: none;
  cursor: pointer;
  text-align: center;
  margin: 10px 0;
`;

const CancelButton = styled(Button)`
  background-color: #7446f5;
  color: white;
`;

const DeleteButton = styled(Button)`
  background-color: #fcd900;
  color: black;
`;

const Profile: React.FC<ProfileProps> = ({ onBackClick, profileData }) => {
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleOpenPrivacy = () => setIsPrivacyModalOpen(true);
  const handleClosePrivacy = () => setIsPrivacyModalOpen(false);

  const handleOpenTerms = () => setIsTermsModalOpen(true);
  const handleCloseTerms = () => setIsTermsModalOpen(false);

  const handleOpenDelete = () => setIsDeleteModalOpen(true);
  const handleCloseDelete = () => setIsDeleteModalOpen(false);

  const handleDeleteAccount = () => {
    // Логика удаления аккаунта
    console.log('Account deletion confirmed');
    setIsDeleteModalOpen(false);
  };

  return (
    <Container>
      <TopBar>
        <BackButton src={ArrowLeftProfile} alt="Back" onClick={onBackClick} />
        <Title>Profile</Title>
      </TopBar>
      <Header>
        <ProfileImage src={profileData.avatar || ProfilePic} alt="Profile" />
        <UsernameContainer>
          <Username>{profileData.name || 'Unknown'}</Username>
          <InfoContainer>
            <Flag src={profileData.flag || FlagIcon} alt="Country Flag" />
            <InfoText>{profileData.countryCode || 'Unknown'} · Age: {profileData.age || 'Unknown'}</InfoText>
          </InfoContainer>
        </UsernameContainer>
      </Header>
      <StatsContainer>
        <Stat>
          <StatNumber>{profileData.followed || 0}</StatNumber>
          <StatLabel>Follow</StatLabel>
        </Stat>
        <Stat>
          <StatNumber>{profileData.followers_count || 0}</StatNumber>
          <StatLabel>Followers</StatLabel>
        </Stat>
        <Stat>
          <StatNumber>{profileData.following_count || 0}</StatNumber>
          <StatLabel>Following</StatLabel>
        </Stat>
      </StatsContainer>

      <Section>
        <SectionTitle>Contact</SectionTitle>
        <SectionItem onClick={() => window.location.href = 'mailto:info@deus.mobi'}>Contact Us</SectionItem>
      </Section>

      <Section>
        <SectionTitle>Privacy & Security</SectionTitle>
 
        
        {/* Ссылка на политику конфиденциальности */}
<SectionItem onClick={() => window.open('https:///golive/privacy', '_blank')}>
  Privacy Policy
</SectionItem>
<Divider />
{/* Ссылка на условия использования */}
<SectionItem onClick={() => window.open('https://deus.mobi/golive/terms', '_blank')}>
  Terms of Service
</SectionItem>
      </Section>

      <Section>
        <SectionTitle>Account</SectionTitle>
        <SectionItem onClick={handleOpenDelete}>Delete Account</SectionItem>
      </Section>

      {/* Модальное окно для удаления аккаунта */}
      {isDeleteModalOpen && (
        <ModalOverlay>
          <ModalContent>
            <ModalText>Are you sure you want to delete your account? All data will be deleted.</ModalText>
            <DeleteButton onClick={handleDeleteAccount}>Yes</DeleteButton>
            <CancelButton onClick={handleCloseDelete}>Cancel</CancelButton>
          </ModalContent>
        </ModalOverlay>
      )}



    </Container>
  );
};

export default Profile;