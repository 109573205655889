import { useStripe, useElements, PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

interface PaymentModalProps {
  label: string; // Название для отображения в платеже (например, "150 Coins")
  amount: number; // Сумма в центах для платежа
  payment_charge_id: number; // ID платежного плана
  onPaymentSuccess: () => void; // Пропс для передачи функции при успешной оплате
}

const PaymentModal: React.FC<PaymentModalProps> = ({ label, amount, payment_charge_id, onPaymentSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentRequest, setPaymentRequest] = useState<any>(null);
  const [canMakePayment, setCanMakePayment] = useState(false);

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: label, // Название пакета монет
          amount: 100, // Сумма в центах
        },
      });

      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);
          setCanMakePayment(true);
        }
      });

      pr.on('paymentmethod', async (event: any) => {
        try {
          const userId = Cookies.get('userId');

          // Отправляем запрос на ваш сервер для создания Payment Intent
          const paymentResponse = await fetch('https://golive.mobi/api/web/create-payment-intent/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
            },
            body: JSON.stringify({
              id: userId,
              payment_method_id: event.paymentMethod.id, // Используем ID, который вернёт Stripe
              payment_charge_id: payment_charge_id, // Динамический ID платежа
              payment_plan_id: null,
            }),
          });

          const paymentData = await paymentResponse.json();

          if (!paymentData.clientSecret) {
            throw new Error('Не удалось получить client_secret');
          }

          // Подтверждение платежа на клиентской стороне с использованием client_secret
          const { paymentIntent, error } = await stripe.confirmCardPayment(paymentData.clientSecret, {
            payment_method: event.paymentMethod.id, // Используем payment_method_id
          });

          if (error) {
            event.complete('fail');
            console.error('Ошибка подтверждения платежа:', error);
          } else {
            event.complete('success');
            handleSuccessfulPayment();
          }
        } catch (error) {
          console.error('Ошибка при обработке платежа:', error);
          event.complete('fail');
        }
      });
    }
  }, [stripe, amount, label, payment_charge_id]); // Добавляем зависимости для обновления при изменении пропсов

  const handleSuccessfulPayment = async () => {
    const userId = Cookies.get('userId');
    
    console.log('Payment successful for user', userId);

    // Вызов функции при успешной оплате
    onPaymentSuccess(); // Открываем новое модальное окно в RandomCall или обрабатываем результат
  };

  return (
    <>
      {canMakePayment && paymentRequest && (
        <PaymentRequestButtonElement
          options={{
            paymentRequest,
            style: {
              paymentRequestButton: {
                type: 'default',
                theme: 'dark',
                height: '64px',
              },
            },
          }}
        />
      )}
    </>
  );
};

export default PaymentModal;