import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import Cookies from 'js-cookie';
import storeIcon from '../res/lownavbar/store.png';
import listIcon from '../res/lownavbar/list.png';
import videochatIcon from '../res/lownavbar/videochat.png';
import textchatIcon from '../res/lownavbar/textchat.png';
import feedcamera from '../res/feedcamera.png';
import feedmessage from '../res/feedmessage.png';
import InChat from './InChat';
import WaitPrivateCall from './WaitPrivateCall';

interface FeedProps {
  onStoreClick: () => void; 
  onListClick: () => void;
  onVideoChatClick: () => void;
  onTextChatClick: () => void;
  onChatClick: (chat: any) => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px 10px;
  box-sizing: border-box;
  padding-bottom: 80px;
`;

const TabBar = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
`;

const TabButton = styled.button<{ active: boolean }>`
  font-size: 20px;
  font-weight: bold;
  background: none;
  border: none;
  color: ${props => (props.active ? '#000' : '#888')};
  border-bottom: ${props => (props.active ? '2px solid #6c63ff' : 'none')};
  cursor: pointer;
  padding: 10px 15px;
  margin-right: 15px;
`;

const FilterBar = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 20px;
`;

const FilterButton = styled.button<{ active: boolean }>`
  display: flex;
  align-items: center;
  background-color: ${props => (props.active ? '#fffbea' : '#f0f0f0')};
  border: 1px solid #ddd;
  border-radius: 20px;
  padding: 5px 15px;
  font-size: 14px;
  font-weight: bold;
  color: ${props => (props.active ? '#000' : '#888')};
  cursor: pointer;

  &:hover {
    background-color: #fffbea;
  }

  & img {
    margin-right: 5px;
  }
`;

const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  gap: 10px;
`;

const Card = styled.div`
  position: relative;
  width: calc(50% - 10px);
  height: 260px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
`;

const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  background: linear-gradient(rgba(0, 0, 0, 0.7), transparent);
  color: #fff;
`;

const SmallAvatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid white;
  padding: 2px;
  background: linear-gradient(45deg, #ff4b2b, #ff416c);
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  overflow: hidden;
`;

const Status = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  white-space: nowrap;
`;

const StatusDot = styled.div<{ color: string }>`
  width: 8px;
  height: 8px;
  background-color: ${props => props.color};
  border-radius: 50%;
  margin-right: 5px;
`;

const Name = styled.div`
  font-size: 16px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const NavBar = styled.div`
  width: 100%;
  height: 60px;
  padding: 10px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  bottom: 0;
  background-color: #fff;
  border-top: 1px solid #e0e0e0;
  z-index: 1000;
`;

const NavItem = styled.img`
  width: 30px;
  height: 30px;
  cursor: pointer;
`;

const IconButton = styled.img`
  position: absolute;
  bottom: 1px;
  right: 1px;
  width: 75px;
  height: 75px;
  cursor: pointer;
`;

const Feed: React.FC<FeedProps> = ({ onStoreClick, onListClick, onVideoChatClick, onTextChatClick }) => {
  const [activeTab, setActiveTab] = useState<'Explore' | 'Followed'>('Explore');
  const [activeFilter, setActiveFilter] = useState<'Hot' | 'Online' | 'New' | 'All'>('Hot');
  const [profiles, setProfiles] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const loaderRef = useRef<HTMLDivElement>(null);
  const [selectedChat, setSelectedChat] = useState<any | null>(null);
  const [waitingForPrivateCall, setWaitingForPrivateCall] = useState<any | null>(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const isMobilePlatform = () => {
    const userAgent = navigator.userAgent || navigator.vendor;
  
    // Проверяем Android
    if (/android/i.test(userAgent)) {
      return true;
    }
  
    // Проверяем iOS
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return true;
    }
  
    return false;
  };

  const isMobile = isMobilePlatform(); // Определяем мобильное устройство

  // Применяем стили: если не мобильное устройство, ширина 25% и центрируем
  const containerStyle = !isMobile ? { width: '25%', left: 'auto', right: 'auto' } : {};

  useEffect(() => {
    loadProfiles(1, 5); // Загружаем первые 5 страниц
    const interval = setInterval(() => {
      loadProfiles(1, 5); // Обновляем загруженные страницы каждые 3 секунды
    }, 3000);
    return () => clearInterval(interval);
  }, [page]);

  const loadMoreProfiles = async (startPage: number, endPage: number) => {
    const userIdFromCookie = Cookies.get('userId');
    if (!userIdFromCookie || loading) return;
    setLoading(true);
    try {
      const loadedProfiles: any[] = [];
      for (let pageNum = startPage; pageNum <= endPage; pageNum++) {
        const response = await axios.post(
          'https://golive.mobi/api/feed/list/',
          {
            id: userIdFromCookie,
            extra: 'all',
            page: pageNum,
          },
          {
            headers: {
              Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
              'Content-Type': 'application/json',
            },
          }
        );
        loadedProfiles.push(...response.data.list);
      }
      setProfiles((prevProfiles) => [...prevProfiles, ...loadedProfiles]);
      setPage(endPage);
      setIsLoadingMore(false);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching more profiles:', error);
      setLoading(false);
      setIsLoadingMore(false);
    }
  };

  const loadProfiles = async (startPage: number, endPage: number) => {
    const userIdFromCookie = Cookies.get('userId');
    if (!userIdFromCookie || loading) return;
    setLoading(true);
    try {
      const loadedProfiles: any[] = [];
      for (let pageNum = startPage; pageNum <= endPage; pageNum++) {
        const response = await axios.post(
          'https://golive.mobi/api/feed/list/',
          {
            id: userIdFromCookie,
            extra: 'all',
            page: pageNum,
          },
          {
            headers: {
              Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
              'Content-Type': 'application/json',
            },
          }
        );
        loadedProfiles.push(...response.data.list);
      }
      setProfiles((prevProfiles) => [...prevProfiles, ...loadedProfiles]);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching feed:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (loaderRef.current && !loading && !isLoadingMore) {
        const { bottom } = loaderRef.current.getBoundingClientRect();
        if (bottom <= window.innerHeight) {
          setIsLoadingMore(true);
          loadMoreProfiles(page + 1, page + 5); // Загружаем еще 5 страниц при скролле
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loading, isLoadingMore, page]);

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'online':
      case 'online_background':
        return 'green';
      case 'busy':
        return 'red';
      default:
        return 'gray';
    }
  };

  const getStatusText = (status: string) => {
    switch (status) {
      case 'online':
      case 'online_background':
        return 'Available';
      case 'busy':
        return 'In call';
      default:
        return 'Recently active';
    }
  };

  const getIconForStatus = (status: string) => {
    if (status === 'online' || status === 'online_background') {
      return feedcamera;
    } else {
      return feedmessage;
    }
  };

  const handleIconClick = (profile: any) => {
    if (profile.status === 'online' || profile.status === 'online_background') {
      setWaitingForPrivateCall(profile);
    } else {
      setSelectedChat(profile);
    }
  };

  if (waitingForPrivateCall) {
    return (
      <WaitPrivateCall 
        userData={waitingForPrivateCall}
        onCancel={() => setWaitingForPrivateCall(null)} 
      />
    );
  }

  if (selectedChat) {
    return (
      <InChat
        chat={selectedChat}
        onBack={() => setSelectedChat(null)}
      />
    );
  }

  return (
    <>
      <Container>
        <TabBar>
          <TabButton active={activeTab === 'Explore'} onClick={() => setActiveTab('Explore')}>
            Explore
          </TabButton>
        {/*  <TabButton active={activeTab === 'Followed'} onClick={() => setActiveTab('Followed')}>
            Followed
          </TabButton> */}
        </TabBar>
        <FilterBar>
          <FilterButton active={activeFilter === 'Hot'} onClick={() => setActiveFilter('Hot')}>
            🔥 Hot
          </FilterButton>
          <FilterButton active={activeFilter === 'Online'} onClick={() => setActiveFilter('Online')}>
            👋 Online
          </FilterButton>
          <FilterButton active={activeFilter === 'New'} onClick={() => setActiveFilter('New')}>
            🌟 New
          </FilterButton>
          <FilterButton active={activeFilter === 'All'} onClick={() => setActiveFilter('All')}>
            🌍 All
          </FilterButton>
        </FilterBar>
        <CardsContainer>
          {profiles.map((profile, index) => (
            <Card key={index}>
              <AvatarImage src={`https://golive.mobi${profile.avatar}`} alt="Avatar" />
              <Overlay>
                <SmallAvatar src={`https://golive.mobi${profile.avatar}`} alt="Avatar" />
                <TextContainer>
                  <Status>
                    <StatusDot color={getStatusColor(profile.status)} />
                    {getStatusText(profile.status)}
                  </Status>
                  <Name>{profile.name}</Name>
                </TextContainer>
              </Overlay>
              <IconButton
                src={getIconForStatus(profile.status)}
                alt="Icon"
                onClick={() => handleIconClick(profile)}
              />
            </Card>
          ))}
        </CardsContainer>
        <div ref={loaderRef} style={{ height: '20px' }} />
      </Container>
      <NavBar style={containerStyle}>
        <NavItem src={storeIcon} alt="Store" onClick={onStoreClick} />
        <NavItem src={listIcon} alt="List" onClick={onListClick} />
        <NavItem src={videochatIcon} alt="Video Chat" onClick={onVideoChatClick} />
        <NavItem src={textchatIcon} alt="Text Chat" onClick={onTextChatClick} />
      </NavBar>
    </>
  );
};

export default Feed;