import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Cookies from 'js-cookie';
import { io, Socket } from 'socket.io-client';
import giftIcon from '../res/gift.png';
import globeIcon from '../res/globe.png';
import crossIcon from '../res/cross.png';
import image11 from '../res/image11.png';
import CallScreen from './CallScreen';

interface WaitConnectProps {
  onCallScreenLoad: (roomId: string, userData: { name: string, avatar: string }) => void;
  onClose: () => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center; /* Центрирование по вертикали */
  align-items: center;     /* Центрирование по горизонтали */
  width: 100vw;
  height: 100vh; /* Занимаем всю высоту экрана */
  box-sizing: border-box;
  overflow: hidden; /* Убираем прокрутку */
  position: relative;
  z-index: 1;

`;

const GradientBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(40,26,95,1) 30%, rgba(40,26,95,1) 70%, rgba(0,0,0,1) 100%);
  z-index: 0;
`;

const CloseButton = styled.img`
  position: absolute;
  top: 4vh;
  left: 2vh;
  width: 4vh;
  height: 4vh;
  cursor: pointer;
  z-index: 2;
`;

const ProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
  width: 80vw;
  position: relative;
  margin-top: 3vh;
  margin-bottom: 3vh;
`;

const Counter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15vw;
  height: 6vh;
  background-color: #ffffff;
  border-radius: 20px;
  border: 3px solid #FCD900;
  font-weight: bold;
  color: black;
  z-index: 1;
`;

const ProgressBarWrapper = styled.div`
  flex-grow: 1;
  height: 1vh;
  background-color: #7C6C44;
  margin-left: 2vw;
  margin-right: 2vw;
  z-index: 0;
`;

const ProgressBarFill = styled.div<{ value: number }>`
  width: ${props => props.value}%;
  height: 100%;
  background-color: #FCD900;
`;

const GiftIconWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const GiftIcon = styled.img`
  width: 8vw;
  height: 8vw;
  z-index: 2;
`;

const InfoText = styled.div`
  font-size: 3vw;
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  margin-bottom: 5vh;
`;

const ImageContainer = styled.div`
  width: 100%;    /* Занимаем всю ширину экрана */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Image = styled.img`
  width: 100%;    /* Ширина на всю доступную область */
  object-fit: cover;   /* Сохраняем пропорции изображения, заполняя контейнер */
  top: 0;
  left: 0;
`;

const TextContainer = styled.div`
  position: absolute;
  bottom: 2vh;
  left: 2vh;
  color: white;
  z-index: 3;
  font-size: 4vw;
  font-weight: bold;
`;

const SubText = styled.div`
  font-size: 3vw;
  font-weight: normal;
`;

const BottomTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80vw;
  margin-top: 3vh;
`;

const GlobeIcon = styled.img`
  width: 10vw;
  height: 10vw;
  margin-right: 2vw;
`;

const BottomText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: rgba(255, 255, 255, 0.6);
`;

const ModalOverlay = styled.div`
  position: fixed;
  bottom: 0; /* Прижимаем модальное окно к нижнему краю */
  left: 0;
  width: 100%; /* Занимаем всю ширину экрана */
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center; /* Центрируем содержимое по горизонтали */
  z-index: 1000;
`;

const ModalContent = styled.div`
  width: 100%;
  background: linear-gradient(165.31deg, #6D33FF 11.36%, #612CE8 37.32%, #0E0033 99.31%);
  border-radius: 10px 10px 0 0;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const Button = styled.button`
  width: 90%;
  padding: 15px;
  font-size: 4vw;
  font-weight: bold;
  border-radius: 25px;
  border: none;
  cursor: pointer;
  text-align: center;
  margin: 5px 0; /* Добавляем небольшой отступ между кнопками */
`;

const EndSearchButton = styled(Button)`
  background-color: #7446F5;
  color: white;
`;

const ContinueButton = styled(Button)`
  background-color: #FCD900;
  color: black;
`;

const ConfirmationText = styled.div`
  font-size: 5vw;
  color: white;
  text-align: center;
  font-weight: bold;
  margin-bottom: 15px; /* Добавляем отступ снизу */
  margin-top: 15px; /* Добавляем отступ сверху */
  width: 70%;
`;

const SadEmoji = styled.span`
  font-size: 5vw;
  margin-left: 10px;
`;

const WaitConnect: React.FC<WaitConnectProps> = ({ onCallScreenLoad, onClose }) => {
  const [userId, setUserId] = useState<string>('');
  const [showCallScreen, setShowCallScreen] = useState<boolean>(false);
  const [dialogId, setDialogId] = useState<string | null>(null);
  const [userData, setUserData] = useState<{ name: string, avatar: string }>({ name: '', avatar: '' });
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const socketRef = useRef<Socket | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null); // Для хранения таймера
  
  useEffect(() => {
      const userIdFromCookie = Cookies.get('userId');
      setUserId(userIdFromCookie || '');
  
      const setupSocket = () => {
          socketRef.current = io('https://sckt.golive.mobi', {
              auth: { user_id: userIdFromCookie, random: 'true' },
          });
  
          if (socketRef.current) {
              socketRef.current.on('push', (rawData) => {
                  if (timeoutRef.current) {
                      clearTimeout(timeoutRef.current); // Сбрасываем таймер
                  }
  
                  let data;
                  try {
                      data = typeof rawData === 'string' ? JSON.parse(rawData) : rawData;
                  } catch (e) {
                      console.error('Failed to parse push data:', e);
                      return;
                  }
  
                  if (data && typeof data === 'object') {
                      if ('dialog_id' in data) {
                          setDialogId(data.dialog_id.toString());
                          setUserData({
                              name: data.name,
                              avatar: `https://golive.mobi${data.avatar}`
                          });
                          setShowCallScreen(true);
                      }
                  } else {
                      console.error('Unexpected data format:', data);
                  }
              });
  
              socketRef.current.on('connect', () => {
                  if (userIdFromCookie) {
                      socketRef.current?.emit('search_v2', { user_id: userIdFromCookie });
                  }
  
                  // Устанавливаем таймер на 5 секунд после подключения
                  timeoutRef.current = setTimeout(() => {
                      if (socketRef.current) {
                          socketRef.current.disconnect();
                          setupSocket(); // Переподключаемся
                      }
                  }, 5000); // 5 секунд
              });
  
              socketRef.current.on('disconnect', (reason) => {
                  console.log('Socket disconnected:', reason);
              });
  
              socketRef.current.on('connect_error', (error: Error) => {
                  console.error('Connection error:', error);
              });
          }
      };
  
      setupSocket(); // Инициализируем сокет
  
      return () => {
          if (socketRef.current) {
              socketRef.current.disconnect();
          }
          if (timeoutRef.current) {
              clearTimeout(timeoutRef.current); // Очищаем таймер при размонтировании компонента
          }
      };
  }, []);
  
  const handleClose = () => {
      setIsModalOpen(true);
  };
  
  const handleModalClose = () => {
      setIsModalOpen(false);
      onClose();
  };
  
  useEffect(() => {
      if (showCallScreen && dialogId) {
          onCallScreenLoad(dialogId, userData);
      }
  }, [showCallScreen, dialogId, onCallScreenLoad, userData]);
  
  const handleContinue = () => {
      setIsModalOpen(false); // Закрыть модальное окно
  };
  
  const handleEndSearch = () => {
      setIsModalOpen(false); // Закрыть модальное окно
      onClose(); // Вернуться в RandomCall
  };

return (
  <>
    <GradientBackground style={{ 'overflow': 'hidden', 'position': 'fixed' }} />
    <Container style={{ 'overflow': 'hidden', 'position': 'fixed' }}>
      <CloseButton src={crossIcon} alt="Close" onClick={handleClose} />
      
      <ImageContainer>
        <Image src={image11} alt="Brielle, 22" />
      </ImageContainer>

      <BottomTextContainer>
        <GlobeIcon src={globeIcon} alt="Globe" />
        <BottomText>
          <span style={{ color: 'white', fontSize: '5vw', fontWeight: 'bold' }}>Waiting for a call...</span>
          <span style={{ fontSize: '4vw' }}>Average wait time 30 seconds</span>
        </BottomText>
      </BottomTextContainer>
    </Container>

    {isModalOpen && (
      <ModalOverlay>
        <ModalContent>
          <ConfirmationText>
            Are you sure you want to exit?
            <SadEmoji>😢</SadEmoji>
          </ConfirmationText>
          <EndSearchButton onClick={handleEndSearch}>End Search</EndSearchButton>
          <ContinueButton onClick={handleContinue}>Continue</ContinueButton>
        </ModalContent>
      </ModalOverlay>
    )}
  </>
);
};

export default WaitConnect;