import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Cookies from 'js-cookie';
import { io, Socket } from 'socket.io-client';
import axios from 'axios';
import EndCallIcon from '../res/EndCall.png';
import CallScreen from './CallScreen';

interface WaitPrivateCallProps {
  userData: {
    id: string;
    name: string;
    avatar: string;
  };
  onCancel: () => void; // Функция для обработки отмены
}

const GradientBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(40,26,95,1) 30%, rgba(40,26,95,1) 70%, rgba(0,0,0,1) 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  flex-direction: column;
`;

const AvatarCircle = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
`;

const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const IconContainer = styled.div`
  position: absolute;
  bottom: 60px;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Icon = styled.img`
  width: 60px;
  height: 60px;
  cursor: pointer;
`;

const PrivateCallText = styled.div`
  font-size: 16px;
  color: white;
  font-weight: bold;
  position: absolute;
  bottom: 20px;
  text-align: center;
  width: 100%;
`;

const WaitPrivateCall: React.FC<WaitPrivateCallProps> = ({ userData, onCancel }) => {
  const socketRef = useRef<Socket | null>(null);
  const [dialogId, setDialogId] = useState<string | null>(null);

  useEffect(() => {
    const userId = Cookies.get('userId');

    if (!userId) {
      console.error('User ID not found in cookies');
      return;
    }

    console.log(`Selected user ID: ${userData.id}`);

    // Подключение к Socket.io серверу
    socketRef.current = io('https://sckt.golive.mobi', {
      auth: { user_id: userId },
    });

    socketRef.current.on('connect', () => {
      console.log('Connected to socket');

      // После подключения отправляем POST-запрос
      axios.post(
        'https://golive.mobi/api/feed/call/socket/',
        {
          user2_id: userData.id,
          id: userId,
        },
        {
          headers: {
            Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
            'Content-Type': 'application/json',
          },
        }
      )
      .then(response => {
        console.log('Call initiated successfully:', response.data);

        // Если model_in_random = 1, вызываем onCancel
        if (response.data.model_in_random === 0) {
          console.log('Model is in random, cancelling...');
          onCancel();
        } else {
          setDialogId(response.data.dialog_id.toString()); // Преобразуем dialog_id в строку и сохраняем
        }
      })
      .catch(error => {
        console.error('Error initiating call:', error);
      });
    });

    socketRef.current.on('disconnect', (reason) => {
      console.log('Socket disconnected:', reason);
    });

    socketRef.current.on('connect_error', (error: Error) => {
      console.error('Connection error:', error);
    });

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, [userData.id]);

  if (dialogId) {
    return (
      <CallScreen
        roomId={dialogId}
        userData={{ name: userData.name, avatar: `https://golive.mobi${userData.avatar}` }}
        onClose={onCancel}
        onTimerEndOrContinue={onCancel}  // Передаем пропс здесь
      />
    );
  }

  return (
    <GradientBackground>
      <AvatarCircle>
        <AvatarImage src={`https://golive.mobi${userData.avatar}`} alt="Avatar" />
      </AvatarCircle>
      <IconContainer>
        <Icon src={EndCallIcon} alt="End Call" onClick={onCancel} />
      </IconContainer>
      <PrivateCallText>Private Call</PrivateCallText>
    </GradientBackground>
  );
};

export default WaitPrivateCall;