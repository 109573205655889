import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import Cookies from 'js-cookie';
import inchatArrow from '../res/inchat_arrow.png';
import inchatTranslate from '../res/inchat_translate.png';
import inchatVideo from '../res/inchat_video.png';
import inchatMenu from '../res/inchat_menu.png';
import sendbutton from '../res/sendbutton.png';

interface InChatProps {
  chat: any;
  onBack: () => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  height: 100vh;
  justify-content: space-between;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 5px 20px; /* Уменьшенный отступ */
  box-sizing: border-box;
  border-bottom: 1px solid #ddd;
`;

const BackArrow = styled.img`
  width: 28px;
  height: 28px;
  cursor: pointer;
`;

const ProfileSection = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin-left: 10px;
`;

const ProfileImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  margin-right: 10px;
`;

const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProfileName = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

const ProfileStatus = styled.div`
  font-size: 14px;
  color: #888;
  display: flex;
  align-items: center;
`;

const StatusBadge = styled.div`
  background-color: green;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: 5px;
`;

const IconGroup = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.img`
  width: 28px;
  height: 28px;
  margin-left: 10px;
  cursor: pointer;
`;

const ChatContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  padding: 10px 20px;
  padding-bottom: 60px;
  box-sizing: border-box;
  overflow-y: auto;
`;

const DateSeparator = styled.div`
  text-align: center;
  color: #888;
  font-size: 14px;
  margin: 20px 0;
`;

const Message = styled.div<{ isOwn?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.isOwn ? 'flex-end' : 'flex-start')};
  margin-bottom: 10px;
`;

const MessageBubble = styled.div<{ isOwn?: boolean }>`
  background-color: ${props => (props.isOwn ? '#FFF4CC' : '#F3F3F3')};
  color: #000;
  padding: 10px;
  border-radius: 10px;
  max-width: 80%;
  position: relative;
  font-size: 16px;
`;

const MessageText = styled.div`
  margin-bottom: 5px;
`;

const MessageStatus = styled.div`
  color: #888;
  font-size: 12px;
  text-align: right;
`;

const MessageImage = styled.img`
  width: 100%;
  max-width: 300px;
  border-radius: 10px;
  margin-top: 10px;
`;

const MessageInputContainer = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background-color: white;
  margin-top: 2%;
`;

const PlusIcon = styled.div`
  font-size: 28px;
  margin-right: 10px;
  cursor: pointer;
`;

const MessageInput = styled.input`
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
  font-size: 16px;
`;

const SendButton = styled.img<{ disabled: boolean }>`
  width: 32px;
  height: 32px;
  margin-left: 10px;
  cursor: pointer;
  filter: ${({ disabled }) => (disabled ? 'grayscale(100%)' : 'none')};
`;

const InChat: React.FC<InChatProps> = ({ chat, onBack }) => {
  const [messages, setMessages] = useState<any[]>([]);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    const fetchMessages = async () => {
      const userIdFromCookie = Cookies.get('userId');
      try {
        const response = await axios.post('https://golive.mobi/api/chat/sync2/', {
          id: userIdFromCookie,
          user2_id: chat.id,
          direction: 'up',
          timestamp: 0,
        }, {
          headers: {
            'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
            'Content-Type': 'application/json',
          },
        });
        setMessages(response.data.messages);
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };

    fetchMessages();
  }, [chat]);

  const formatTimestamp = (timestamp: number) => {
    const date = new Date(timestamp);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleSendMessage = async () => {
    const userIdFromCookie = Cookies.get('userId');
    if (!inputValue.trim()) return;

    const newMessage = {
      id_from: userIdFromCookie,
      id_to: chat.id,
      message: inputValue,
      reply_id: '-1',
    };

    try {
      await axios.post('https://golive.mobi/api/chat/send/', [newMessage], {
        headers: {
          'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json',
        },
      });

      setMessages([...messages, { ...newMessage, timestamp: Date.now() }]); // Добавляем новое сообщение в список
      setInputValue(''); // Очищаем поле ввода
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  return (
    <Container>
      <Header>
        <BackArrow src={inchatArrow} alt="Back" onClick={onBack} />
        <ProfileSection>
          <ProfileImage src={`https://golive.mobi${chat.avatar}`} alt="Profile" />
          <ProfileInfo>
            <ProfileName>{chat.name}, {chat.age}</ProfileName>
            <ProfileStatus>{chat.status === 'online' ? 'Online now' : 'Offline'}<StatusBadge /></ProfileStatus>
          </ProfileInfo>
        </ProfileSection>
       {/* <IconGroup>
          <Icon src={inchatTranslate} alt="Translate" />
          <Icon src={inchatVideo} alt="Video Call" />
          <Icon src={inchatMenu} alt="Menu" />
        </IconGroup> */}
      </Header>
      <ChatContainer>
        <DateSeparator>Today</DateSeparator>
        {messages.map((message, index) => (
          <Message key={index} isOwn={message.id_from === Cookies.get('userId')}>
            <MessageBubble isOwn={message.id_from === Cookies.get('userId')}>
              {message.message && <MessageText>{message.message}</MessageText>}
              {message.file_url && (
                <MessageImage src={`https://golive.mobi${message.file_url}`} alt="Image" />
              )}
              <MessageStatus>{formatTimestamp(message.timestamp)}</MessageStatus>
            </MessageBubble>
          </Message>
        ))}
      </ChatContainer>
      <MessageInputContainer>
       {/* <PlusIcon>+</PlusIcon> */}
        <MessageInput 
          type="text" 
          placeholder="Message..." 
          value={inputValue} 
          onChange={handleInputChange} 
        />
        <SendButton 
          src={sendbutton} 
          alt="Send" 
          disabled={!inputValue.trim()} 
          onClick={handleSendMessage}
        />
      </MessageInputContainer>
    </Container>
  );
};

export default InChat;