import React from 'react';
import styled from 'styled-components';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1000;
`;

const ModalContent = styled.div`
  position: relative;
  width: 100%;
  background: linear-gradient(165.31deg, #6D33FF 11.36%, #612CE8 37.32%, #0E0033 99.31%);
  border-radius: 10px 10px 0 0;
  overflow: hidden;
`;

const ModalContentSecond = styled.div`
  position: relative;
  width: 100%;
  height: 55%;
  background: linear-gradient(165.31deg, #6D33FF 11.36%, #612CE8 37.32%, #0E0033 99.31%);
  border-radius: 10px 10px 0 0;
  overflow: hidden;
`;

interface CustomModalProps {
  isOpen: boolean;
  variant?: 'primary' | 'secondary';
  onClose: () => void;
  children?: React.ReactNode;  // Здесь мы указываем, что children - это опциональный проп типа React.ReactNode
}

const ModalCountry: React.FC<CustomModalProps> = ({ isOpen, variant = 'primary', onClose, children }) => {
  if (!isOpen) return null;

  const isMobilePlatform = () => {
    const userAgent = navigator.userAgent || navigator.vendor;
  
    // Проверяем Android
    if (/android/i.test(userAgent)) {
      return true;
    }
  
    // Проверяем iOS
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return true;
    }
  
    return false;
  };

  const isMobile = isMobilePlatform(); // Определяем мобильное устройство

  // Применяем стили: если не мобильное устройство, ширина 25% и центрируем
  const containerStyle = !isMobile ? { width: '25%', left: 'auto', right: 'auto' } : {};

  return (
    <ModalOverlay onClick={onClose} style={containerStyle}>
      {variant === 'primary' ? (
        <ModalContent onClick={(e) => e.stopPropagation()}>
          {children}
        </ModalContent>
      ) : (
        <ModalContentSecond onClick={(e) => e.stopPropagation()}>
          {children}
        </ModalContentSecond>

        
      )}
    </ModalOverlay>
  );
};

export default ModalCountry;