import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import CustomButton from '../components/CustomButton';
import Cookies from 'js-cookie'; // Импортируем js-cookie
import RandomCall from './RandomCall';
import '../res/fonts/CIRCULAR20CYR_BLACK.OTF'; // Импортируем шрифт
import popupclosebutton from '../res/popupclosebutton.png';
import f_girl from '../res_land/f_girl.png'
import f_button from '../res_land/f_button.png'
import f_equals from '../res_land/f_equals.png'
import f_logo from '../res_land/f_logo.png'
import f_bar from '../res_land/f_bar.png'
import f_bar2 from '../res_land/f_bar2.png'
import f_text1 from '../res_land/f_text1.png'
import f_text2 from '../res_land/f_text2.png'
import f_signpic from '../res_land/f_signpic.png'
import f_textdownload from '../res_land/f_textdonwload.png'
import f_appstore from '../res_land/f_appstore.png'
import f_googleplay from '../res_land/f_googleplay.png'
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import AppleLogin from 'react-apple-login';
import FAQ from './FAQ'

// Импортируем изображения из ../res
import allowservices from '../res/allowservices.png';
import allowservices2 from '../res/allowservices2.png';
import allusersoff from '../res/allusersoff.png';
import alluserson from '../res/alluserson.png';
import ArrowLeftProfile from '../res/ArrowLeftProfile.png';
import av_store from '../res/av_store.png';
import avatar_temp from '../res/avatar_temp.png';
import avatar from '../res/avatar.png';
import checkoff from '../res/checkoff.png';
import checkon from '../res/checkon.png';
import cloud_message from '../res/cloud_message.png';
import cloud_phone from '../res/cloud_phone.png';
import coin from '../res/coin.png';
import cross from '../res/cross.png';
import demogirlchat from '../res/demogirlchat.png';
import dotsthree from '../res/dotsthree.png';
import EndCall from '../res/EndCall.png';
import feedcamera from '../res/feedcamera.png';
import feedmessage from '../res/feedmessage.png';
import filterswitchoff from '../res/filterswitchoff.png';
import filterswitchon from '../res/filterswitchon.png';
import freetrialsecond from '../res/freetrialsecond.png';
import gift from '../res/gift.png';
import giftcall from '../res/giftcall.png';
import globe from '../res/globe.png';
import hand from '../res/hand.png';
import handtapstart from '../res/handtapstart.png';
import heart from '../res/heart.png';
import image1 from '../res/image1.png';
import image2 from '../res/image2.png';
import image3 from '../res/image3.png';
import image11 from '../res/image11.png';
import inchat_arrow from '../res/inchat_arrow.png';
import inchat_menu from '../res/inchat_menu.png';
import inchat_translate from '../res/inchat_translate.png';
import inchat_video from '../res/inchat_video.png';
import lastchance from '../res/lastchance.png';
import logo_splash from '../res/logo_splash.png';
import manycoins from '../res/manycoins.png';
import micro from '../res/micro.png';
import minimaze from '../res/minimaze.png';
import paycongratulations from '../res/paycongratulations.png';
import payingcard1 from '../res/payingcard1.png';
import payingcard2 from '../res/payingcard2.png';
import premiumusersoff from '../res/premiumusersoff.png';
import premiumuserson from '../res/premiumuserson.png';
import sendbutton from '../res/sendbutton.png';
import set_filter from '../res/set_filter.png';
import startvip from '../res/startvip.png';
import strange_s from '../res/strange_s.png';
import timewatch from '../res/timewatch.png';
import topup1 from '../res/topup1.png';
import topup2 from '../res/topup2.png';
import topupbalance from '../res/topupbalance.png';
import translate from '../res/translate.png';
import US from '../res/US.png';
import VideoCamera from '../res/VideoCamera.png';

import APB from './APB'

declare global {
  interface Window {
    AppleID: any; // Можно заменить `any` на точную типизацию, если потребуется
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Изменено на flex-start, чтобы элементы шли друг за другом */
  align-items: center;
  height: 100vh;
  box-sizing: border-box;
  overflow-x: hidden;
`;



const Title = styled.h1`
  font-family: 'CIRCULAR20CYR_BLACK', sans-serif;
  font-size: 42px;
  text-align: center;
  margin-bottom: 20px;
`;

const Input = styled.input`
  width: 100%;
  max-width: 400px;
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 255, 0.2);
`;

const Disclaimer = styled.p`
  font-size: 0.8em;
  color: #999;
  text-align: center;
  max-width: 400px;
  margin-top: 10px;
  a {
    color: #666;
    text-decoration: none;
  }
`;

const CustomButtonContainer = styled.div`
  width: 100%;
  max-width: 400px;
  margin-top: 20px;
`;

const ImageWrapper = styled.div`
  position: relative; 
  width: 100%;
  z-index: 2; /* Убедимся, что ImageWrapper находится поверх GradientBlock */
`;

const Image = styled.img`
  width: 100%; /* Растянуть изображение на всю ширину */
  height: auto; /* Сохранить пропорции изображения */
  margin-bottom: 0;
`;

const TopOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40%; /* Затемняем верхние 20% изображения */
  background: linear-gradient(180deg, rgba(0, 0, 0, 1) 15%, rgba(0, 0, 0, 0) 100%);
`;

const BottomOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40%; /* Затемняем нижние 20% изображения */
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 15%, rgba(0, 0, 0, 0) 100%);
`;

const GradientBlock = styled.div`
  width: 100%; 
  background: linear-gradient(0deg, #561BEB 0%, #000000 100%); 
  display: flex;
  flex-direction: column; 
  justify-content: center;
  align-items: center;
  position: relative; /* Блок не должен перекрывать предыдущие элементы */
  z-index: 1; /* Низкий z-index, чтобы не перекрывать ImageWrapper */
  margin-top: 0; /* Убедимся, что нет лишних отступов между ImageWrapper и GradientBlock */
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
`;

const GradientBlock2 = styled.div`
  width: 100%;
  background: linear-gradient(0deg, #561BEB 0%, #000000 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
`;

const TextImage = styled.img`
  width: 80%;
  height: auto;
  margin: 0 auto;
  padding: 20px;
  display: block;
`;

const DownloadSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
`;

const DownloadImage = styled.img`
  margin-bottom: 20px;
  width: 50%;  /* Уменьшаем размер текста "Download App" */
  height: auto;
`;

const AppButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;  /* Кнопки Google Play и App Store друг под другом */
  justify-content: center;
  gap: 15px;
`;

const AppButton = styled.img`
  width: 300px;
  height: auto;
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 0;
  background-color: #561BEB;
  color: #fff;
  font-size: 14px;
  text-align: center;
`;

const FooterLink = styled.a`
  color: #fff;
  margin: 0 15px;
  text-decoration: none;
  font-weight: bold;
  &:hover {
    text-decoration: underline;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  z-index: 5;

  /* Добавляем длинное затемнение */
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 200px; /* Увеличиваем высоту затемнения */
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 15%, rgba(0, 0, 0, 0) 100%);
    z-index: -1; /* Затемнение за контентом */
  }
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;
  z-index: 5;
`;

const LogoImage = styled.img`
  margin-left: 10px;
  width: 80px;  /* Уменьшите ширину логотипа */
  height: auto; /* Сохраняем пропорции */
  z-index: 5;
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
  z-index: 5;
`;

const HeaderImage = styled.img`
  width: 40px;  /* Установите фиксированные размеры для других изображений */
  height: auto; /* Сохраняем пропорции */
`;

const BarImage = styled.img`
  position: absolute;
  bottom: 20px;  /* Отступ от нижнего края */
  left: 50%;
  transform: translateX(-50%);  /* Центрируем по горизонтали */
  width: 100%;  /* Сделаем изображение меньше, измените процент, если нужно */
  height: auto;
  padding: 0 20px;  /* Отступы по бокам */
  z-index: 2;  /* Размещаем изображение над затемнением */
`;

// Стиль для модального окна
// Стиль для модального окна, прижимаем его к низу
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); // Затемнение фона
  display: flex;
  justify-content: center;
  align-items: flex-end; // Прижимаем модальное окно к низу
  z-index: 1000;
`;

const ModalContent = styled.div`
  position: relative;
  width: 100%;
  max-width: 400px; // Ограничиваем максимальную ширину
  background: linear-gradient(165.31deg, #6D33FF 11.36%, #612CE8 37.32%, #0E0033 99.31%);
  border-radius: 10px 10px 0 0; // Скругляем только верхние углы
  padding: 20px;
  text-align: center;
  height: auto; // Высота окна зависит от содержимого
`;

const CloseButton = styled.img`
  position: absolute;
  top: 10px;
  left: 10px;
  width: 40px;
  height: 40px;
  cursor: pointer;
`;

const ModalImage = styled.img`
  margin-top: 20px; // Отступ сверху
  width: 80%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

// Стиль для контейнера кнопок
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px; // Расстояние между кнопками
  margin-top: 20px;
`;

// Стиль для кнопки Google и Apple
const AuthButton = styled.button`
  width: 80%; // Ширина кнопки
  padding: 15px;
  font-size: 18px;
  font-weight: bold;
  color: black;
  background-color: #FCD900; // Цвет кнопки
  border: none;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e5c838;
  }
`;

// Стиль для текста внизу
const DisclaimerText = styled.p`
  font-size: 14px;
  color: white;
  text-align: center;
  margin-top: 20px;
  padding: 0 20px; // Для отступов по бокам
  line-height: 1.4;

  a {
    color: #FFD700;
    text-decoration: underline;
  }
`;





const generateRandomUsername = () => {
  const randomNumbers = Math.floor(10000 + Math.random() * 90000);
  return `User_${randomNumbers}`;
};

const getRandomAge = () => {
  return Math.floor(20 + Math.random() * 29);
};

interface RegistrationProps {
  onRegistrationSuccess: (userId: string) => void;
}

const Registration: React.FC<RegistrationProps> = ({ onRegistrationSuccess }) => {
  const [username, setUsername] = useState(generateRandomUsername());
  const [isRegistered, setIsRegistered] = useState(false);
  const randomCallsValue = localStorage.getItem('randomCalls');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isIOS, setIsIOS] = useState<boolean | null>(null);

  const isMobilePlatform = () => {
    const userAgent = navigator.userAgent || navigator.vendor;
  
    // Проверяем Android
    if (/android/i.test(userAgent)) {
      return true;
    }
  
    // Проверяем iOS
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return true;
    }
  
    return false;
  };

  const isMobile = isMobilePlatform(); // Определяем мобильное устройство

  // Применяем стили: если не мобильное устройство, ширина 25% и центрируем
  const containerStyle = !isMobile ? { width: '25%', left: 'auto', right: 'auto' } : {};

  useEffect(() => {
    // Определение платформы (iOS или Android)
    const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;

    if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
      setIsIOS(true); // Если iOS
    } else if (/android/i.test(userAgent)) {
      setIsIOS(false); // Если Android
    } else {
      setIsIOS(null); // Другое устройство
    }
  }, []);

  const preloadImages = (images: string[]) => {
    images.forEach((src) => {
      const img = document.createElement('img'); // Создаем элемент через document.createElement
      img.src = src;
    });
  };

  useEffect(() => {
    const imagesToPreload = [
      allowservices,
      allowservices2,
      allusersoff,
      alluserson,
      ArrowLeftProfile,
      av_store,
      avatar_temp,
      avatar,
      checkoff,
      checkon,
      cloud_message,
      cloud_phone,
      coin,
      cross,
      demogirlchat,
      dotsthree,
      EndCall,
      feedcamera,
      feedmessage,
      filterswitchoff,
      filterswitchon,
      freetrialsecond,
      gift,
      giftcall,
      globe,
      hand,
      handtapstart,
      heart,
      image1,
      image2,
      image3,
      image11,
      inchat_arrow,
      inchat_menu,
      inchat_translate,
      inchat_video,
      lastchance,
      logo_splash,
      manycoins,
      micro,
      minimaze,
      paycongratulations,
      payingcard1,
      payingcard2,
      popupclosebutton,
      premiumusersoff,
      premiumuserson,
      sendbutton,
      set_filter,
      startvip,
      strange_s,
      timewatch,
      topup1,
      topup2,
      topupbalance,
      translate,
      US,
      VideoCamera,
    ];
  
    preloadImages(imagesToPreload);
  }, []);
  
  const handleLogoClick = () => {
    setIsModalOpen(true);
  };

  const handleBarClick = () => {
    setIsModalOpen(true);
  };

  const handleButtonClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Функция для регистрации пользователя через API
  const handleSubmit = async () => {
    const age = getRandomAge();

    const response = await fetch('https://golive.mobi/api/user/signup/', {
      method: 'POST',
      headers: {
        Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: username,
        age: age,
        gender: 1,
        web: 1
      }),
    });

    if (response.ok) {
      const data = await response.json();
      console.log('Success:', data);

      // Сохраняем id в cookies
      Cookies.set('userId', data.id, { expires: 365 }); // Cookies будут действительны в течение 365 дней

      // Устанавливаем состояние регистрации в true
      setIsRegistered(true);

      // Уведомляем родительский компонент о успешной регистрации
      onRegistrationSuccess(data.id);
    } else {
      console.error('Error:', response.statusText);
    }
  };

  // Обработка успешной Google авторизации
  const handleGoogleSuccess = async (response: any) => {
    const googleToken = response.credential;
    console.log('Google ID Token:', googleToken);

    const base64Url = googleToken.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const decodedToken = JSON.parse(window.atob(base64));
  
  const email = decodedToken.email; // Извлекаем email
  
  console.log('Google Email:', email);

    try {
      // Проверка токена Google
      const checkResponse = await fetch('https://golive.mobi/api/web/user/token/check/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
        },
        body: JSON.stringify({
          gp_id: googleToken,
          apple_id: null,
        }),
      });

      const checkData = await checkResponse.json();

      if (checkResponse.ok && !checkData.id) {
        // Если нет id, создаем пользователя
        const age = getRandomAge();

        const signUpResponse = await fetch('https://golive.mobi/api/user/signup/', {
          method: 'POST',
          headers: {
            Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: generateRandomUsername(),
            age: age,
            gender: 1,
            email: email,
            web: 1
          }),
        });

        const signUpData = await signUpResponse.json();

        if (signUpResponse.ok && signUpData.id) {
          const userId = signUpData.id;

          // Сохраняем user_id в куки
          Cookies.set('userId', userId, { expires: 365 });

          // Привязываем токен Google к созданному пользователю
          const attachResponse = await fetch('https://golive.mobi/api/web/user/token/attach/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
            },
            body: JSON.stringify({
              id: userId,
              gp_id: googleToken,
              apple_id: null,
            }),
          });

          const attachData = await attachResponse.json();

          if (attachResponse.ok && attachData.id) {
            setIsRegistered(true);
            onRegistrationSuccess(userId);
          } else {
            console.error('Ошибка привязки токена:', attachData);
          }
        }
      } else if (checkData.id) {
        // Если id существует, используем уже существующего пользователя
        const userId = checkData.id;
        Cookies.set('userId', userId, { expires: 365 });

        setIsRegistered(true);
        onRegistrationSuccess(userId);
      }
    } catch (error) {
      console.error('Error during Google authentication:', error);
    }
  };

  // Обработка успешной Apple авторизации
  const handleAppleSuccess = async (response: any) => {
    const appleIdToken = response.authorization.id_token;
    console.log('Apple ID Token:', appleIdToken);

    try {
      // Проверка токена Apple
      const checkResponse = await fetch('https://golive.mobi/api/web/user/token/check/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
        },
        body: JSON.stringify({
          gp_id: null,
          apple_id: appleIdToken,
        }),
      });

      const checkData = await checkResponse.json();

      if (checkResponse.ok && !checkData.id) {
        // Если нет id, создаем пользователя
        const age = getRandomAge();

        const signUpResponse = await fetch('https://golive.mobi/api/user/signup/', {
          method: 'POST',
          headers: {
            Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: generateRandomUsername(),
            age: age,
            gender: 1,
          }),
        });

        const signUpData = await signUpResponse.json();

        if (signUpResponse.ok && signUpData.id) {
          const userId = signUpData.id;

          // Сохраняем user_id в куки
          Cookies.set('userId', userId, { expires: 365 });

          // Привязываем токен Apple к созданному пользователю
          const attachResponse = await fetch('https://golive.mobi/api/web/user/token/attach/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
            },
            body: JSON.stringify({
              id: userId,
              gp_id: null,
              apple_id: appleIdToken,
            }),
          });

          const attachData = await attachResponse.json();

          if (attachResponse.ok && attachData.id) {
            setIsRegistered(true);
            onRegistrationSuccess(userId);
          } else {
            console.error('Ошибка привязки токена:', attachData);
          }
        }
      } else if (checkData.id) {
        // Если id существует, используем уже существующего пользователя
        const userId = checkData.id;
        Cookies.set('userId', userId, { expires: 365 });

        setIsRegistered(true);
        onRegistrationSuccess(userId);
      }
    } catch (error) {
      console.error('Error during Apple authentication:', error);
    }
  };


  const handleGoogleFailure = (error: any) => {
    console.error('Google Login Failed:', error);
  };

  if (isRegistered) {
    return <RandomCall onListClick={() => {}} onMatchClick={() => {}} onStoreClick={() => {}} onChatsClick={() => {}} showModal={true} />;
  }


   

  return (
      <Container>
        <Header style={containerStyle}>
          <LeftSection>
            <HeaderImage src={f_equals} alt="Equals" style={{
              'width': '30px',
              'height': 'auto',
              'marginTop': '10%',
            }} />
            <LogoImage src={f_logo} alt="Logo" onClick={handleLogoClick}
            style={{
              'width': '130px',
              'height': 'auto',
              'marginTop': '5%',
            }} />
          </LeftSection>
          <RightSection>
            <HeaderImage src={f_button} alt="Button" onClick={handleButtonClick} style={{
              'width': '100px',
              'height': 'auto',
              'marginTop': '30%',
            }} />
          </RightSection>
        </Header>
    
        <ImageWrapper>
          <Image src={f_girl} alt="Girl" />
          <BarImage src={f_bar2} alt="Bar" onClick={handleBarClick} />  {/* Добавляем f_bar поверх картинки */}
        {/*  <TopOverlay />    Накладываем затемнение на верхние 20% */}
          <BottomOverlay /> {/* Накладываем затемнение на нижние 20% */}
        </ImageWrapper>
    
        <GradientBlock>
          <TextImage src={f_text1} alt="Text 1" />
          <TextImage src={f_text2} alt="Text 2" />  {/* Добавляем второе изображение f_text2 под f_text1 */}
        </GradientBlock>

        <FAQ />

        <GradientBlock2>
        <DownloadSection>
          <DownloadImage src={f_textdownload} alt="Download App" />
          <AppButtonsContainer>
            <AppButton src={f_googleplay} alt="Google Play" />
            <AppButton src={f_appstore} alt="App Store" />
          </AppButtonsContainer>
        </DownloadSection>
      </GradientBlock2>

      <Footer>
      <FooterLink href="https://deus.mobi/golive/privacy" target="_blank" rel="noopener noreferrer">
  Privacy
</FooterLink>
<FooterLink href="https://deus.mobi/golive/terms" target="_blank" rel="noopener noreferrer">
  Terms
</FooterLink>
<FooterLink href="mailto:info@deus.mobi">
  Contact Us
</FooterLink>
      </Footer>

      {/* Модальное окно */}
      {isModalOpen && (
  <ModalOverlay>
    <ModalContent>
      <CloseButton src={popupclosebutton} alt="Close" onClick={closeModal} />
      <ModalImage src={f_signpic} alt="Sign Pic" />

      <ButtonContainer>
  

        <GoogleLogin
          onSuccess={handleGoogleSuccess}
          useOneTap={true}
          auto_select={true}
          cancel_on_tap_outside={false}
          ux_mode="popup"
        />
  
<APB onSuccess={handleAppleSuccess} />
   {/*  <AuthButton onClick={handleSubmit}>Continue with Google</AuthButton>
     <AuthButton onClick={handleSubmit}>Continue with Apple</AuthButton> */}

      </ButtonContainer>


      {/* Текст внизу */}
      <DisclaimerText>
  By logging in, you confirm you’re over 18 years old and agree to our 
  <a href="https://deus.mobi/golive/terms" target="_blank" rel="noopener noreferrer"> Terms of Use </a> 
  and 
  <a href="https://deus.mobi/golive/privacy" target="_blank" rel="noopener noreferrer"> Privacy Policy</a>.
</DisclaimerText>

    </ModalContent>
  </ModalOverlay>
)}
    
      </Container>
  );
};

export default Registration;