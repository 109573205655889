import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Cookies from 'js-cookie';
import storeIcon from '../res/lownavbar/store.png';
import listIcon from '../res/lownavbar/list.png';
import videochatIcon from '../res/lownavbar/videochat.png';
import textchatIcon from '../res/lownavbar/textchat.png';
import handtapstart from '../res/handtapstart.png';
import heartIcon from '../res/heart.png';
import globeIcon from '../res/globe.png';
import popupclosebutton from '../res/popupclosebutton.png';
import CoinIcon from '../res/coin.png';
import startvip from '../res/startvip.png';
import lastchance from '../res/lastchance.png';
import freetrialsecond from '../res/freetrialsecond.png'
import allowservices from '../res/allowservices.png'
import allowservices2 from '../res/allowservices2.png'
import ModalSuperMatch from './ModalSuperMatch';
import ModalCountry from './ModalCountry';
import allusersoff from '../res/allusersoff.png'
import alluserson from '../res/alluserson.png'
import premiumusersoff from '../res/premiumusersoff.png'
import premiumuserson from '../res/premiumuserson.png'
import strange_s from '../res/strange_s.png'
import filterswitchoff from '../res/filterswitchoff.png'
import filterswitchon from '../res/filterswitchon.png'
import checkoff from '../res/checkoff.png'
import checkon from '../res/checkon.png'
import axios from 'axios';
import manycoins from '../res/manycoins.png'
import paycongratulations from '../res//paycongratulations.png'
import payingcard1 from '../res/payingcard1.png'
import payingcard2 from '../res/payingcard2.png'
import { RIGHT } from 'react-swipeable';
import Stripe from '../components/Stripe'
import ModalStore from './ModalStore'
import CenterModal from './CenterModal';

interface RandomCallProps {
  onMatchClick: () => void;
  onStoreClick: () => void;
  onListClick: () => void;
  onChatsClick: () => void;
  showModal: boolean; // Новый пропс для управления видимостью модального окна
}

interface ModalProps {
  onClose: () => void;
  balance: number;
  amount: number;
  paymentChargeId?: number;  // Сделали опциональным
  onPaymentSuccessAndClose: () => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100vh; 
  box-sizing: border-box;
  overflow: hidden;
  position: fixed;
  bottom: 0%;
  z-index: 5;
`;

const GradientBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(40,26,95,1) 30%, rgba(40,26,95,1) 70%, rgba(0,0,0,1) 100%);
  z-index: 0;
  overflow: hidden;
`;

const CentralText = styled.div`
  font-size: 20px;
  color: #fff;
  text-align: center;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  font-weight: 600;
`;

const OnlineUsers = styled.div`
  font-size: 35px;
  color: #fff;
  margin-top: 15vh;
  font-weight: bold;
  text-align: center;
`;

const HandTapStartImage = styled.img`
  width: 250px;
  height: 250px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`;

const MatchButtonContainer = styled.div`
  position: absolute;
  bottom: 8vh;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 60px); 
  z-index: 2;
`;

const MatchButton = styled.button`
  position: relative;
  width: 100%;
  background-color: #FCD900; 
  color: black;
  font-size: 20px; 
  font-weight: bold;
  padding: 18px 0; 
  border: none;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  white-space: nowrap; 
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e5c838;
  }
`;

const FreeBadge = styled.div`
  position: absolute;
  top: -20px;
  right: -1px;
  background-color: #00C013;
  color: white;
  font-size: 17px;
  font-weight: bold;
  padding: 7px 22px;
  border-radius: 20px;
  border-bottom-left-radius: 0px;
`;

const OptionButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px; 
  position: absolute;
  bottom: 20vh; 
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 60px);
  z-index: 2;
`;

const OptionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4B2A7B;
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 30px;
  width: 48%; 
`;

const OptionIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 10px;
`;

const NavBar = styled.div`
  width: 100%;
  height: 60px;
  padding: 10px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
`;

const NavItem = styled.img`
  width: 30px;
  height: 30px;
  cursor: pointer;
`;

// Стилизованный компонент для модального окна с затемнением фона
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); // Затемнение фона
  display: flex;
  justify-content: center;
  align-items: flex-end; // Прижимаем модальное окно к низу
  z-index: 1000; // Высокий z-index для перекрытия всех элементов
`;

const ModalContent = styled.div`
  position: relative;
  width: 100%;
  background: linear-gradient(165.31deg, #6D33FF 11.36%, #612CE8 37.32%, #0E0033 99.31%);
  border-radius: 10px 10px 0 0;
  overflow: hidden; 
`;

const ModalContentSecond = styled.div`
  position: relative;
  width: 100%;
  background: linear-gradient(165.31deg, #6D33FF 11.36%, #612CE8 37.32%, #0E0033 99.31%);
  border-radius: 10px 10px 0 0;
  overflow: hidden; 
`;

const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; 
  padding: 15px 0; // Отступ сверху для кнопки и текста
  width: 100%;
`;

const CloseButton = styled.img`
  position: absolute;
  left: 20px; // Позиционирование кнопки от левого края
  top: 15px; // Позиционирование кнопки от верха
  width: 36px;
  height: 36px;
  cursor: pointer;
`;

const WelcomeText = styled.div`
  font-size: 25px;
  color: #FFFFFF; 
  font-weight: bold;
  margin-top: 5px; // Отступ от верха на том же уровне, что и у CloseButton
  text-align: center;
  flex-grow: 1; // Заполняет оставшееся пространство для центрирования текста
`;

const CountdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  background-color: rgba(0, 0, 0, 0.1); // Цвет с прозрачностью 10%
  padding: 15px 25px;
  border-radius: 20px;
  max-width: 200px; // Уменьшенная ширина контейнера
  margin-left: auto;
  margin-right: auto;
`;

const CountdownText = styled.div`
  font-size: 16px;
  color: #FFFFFF; // Белый цвет без прозрачности для текста "Countdown"
  margin-bottom: 5px;
`;

const Timer = styled.div`
  display: flex;
  align-items: center;
`;

const Time = styled.div<{ isHighlighted?: boolean }>`
  font-size: 24px;
  color: ${({ isHighlighted }) => (isHighlighted ? '#FFFFFF' : 'rgba(255, 255, 255, 0.4)')};
  font-weight: bold;
`;

const Separator = styled.div`
  font-size: 24px;
  color: rgba(255, 255, 255, 0.4); // Белый с прозрачностью 40% для разделителей
  font-weight: bold;
  margin: 0 5px;
`;

const StartVipImage = styled.img`
  margin-top: 20px;
  width: 300px; // Увеличенная ширина изображения
  height: auto; // Автоматическая высота, чтобы сохранить пропорции
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const ContinueVipButton = styled.button`
  margin-top: 20px;
  width: 300px; // Ширина кнопки
  background-color: #FCD900; 
  color: black;
  font-size: 20px; 
  font-weight: bold;
  padding: 15px 0; 
  border: none;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: background-color 0.3s ease;
  margin-left: auto;
  margin-right: auto;
  display: block; // Центрирование кнопки
`;

const AgreementText = styled.div`
  margin-top: 15px;  // Отступ сверху от кнопки "Continue"
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5); // Белый цвет с прозрачностью 50%
  text-align: center;
  max-width: 300px; // Ширина текста, такая же как у кнопки "Continue"
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;  // Отступ снизу до края модального окна

  a {
    color: rgba(255, 255, 255, 0.5);  // Сохраняем цвет текста
    text-decoration: underline;  // Добавляем подчеркивание для ссылок
    cursor: pointer;

    &:hover {
      text-decoration: none;  // Убираем подчеркивание при наведении
    }
  }
`;

const CenteredModalOverlay = styled(ModalOverlay)`
  align-items: center; // Изменяем выравнивание по вертикали на центр
`;

// Стили для CoinsContainer, CoinText, и CoinImage
const CoinsContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 20px;
`;

const CoinText = styled.span`
  font-size: 16px;
  font-weight: bold;
  margin-right: 5px;
  color: white;  // Цвет текста белый для лучшей видимости
`;

const CoinImage = styled.img`
  width: 30px;
  height: 30px;
`;

interface Country {
  code: string;
  flag: string;
  name: string;
}

const RandomCall: React.FC<RandomCallProps & { showModal: boolean }> = ({ onMatchClick, onListClick, onStoreClick, onChatsClick, showModal }) => {
  const [userId, setUserId] = useState<string>('');
  /*const [isModalOpen, setIsModalOpen] = useState<boolean>(showModal && !Cookies.get('modalsDismissed'));*/ // Используем куки, чтобы контролировать показ первого модального окна
  /*const [isModalOpen, setIsModalOpen] = useState(true);*/
  const [isModalOpen, setIsModalOpen] = useState<boolean>(showModal && !localStorage.getItem('DBB98'));
  const [isSecondModalOpen, setIsSecondModalOpen] = useState<boolean>(false); // Состояние для второго модального окна
  const [isThirdModalOpen, setIsThirdModalOpen] = useState<boolean>(false); // Состояние для третьего модального окна
  const [secondTimer, setSecondTimer] = useState(1800); // 1800 секунд = 30 минут
  const [isSuperMatchModalOpen, setIsSuperMatchModalOpen] = useState<boolean>(false);
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<'all' | 'premium'>('all'); // Default state is 'all'
  const [isCountryModalOpen, setIsCountryModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<'balanced' | 'global' | null>(null);
  const [countries, setCountries] = useState<Country[]>([]); // Список стран
  const [selectedCountry, setSelectedCountry] = useState<string | null>(null); // Выбранная страна
  const [isThirdModalEmptyOpen, setIsThirdModalEmptyOpen] = useState<boolean>(false);
  const [balance, setBalance] = useState<number | null>(null);
  const [welcomeTimer, setWelcomeTimer] = useState(30);
  const [isModalStoreOpen, setModalStoreOpen] = useState(false);
  const [isModalStoreOpen2, setModalStoreOpen2] = useState(false);
  const [isCenterModalOpen, setIsCenterModalOpen] = useState<boolean>(false);
  const [isCenterModalOpen2, setIsCenterModalOpen2] = useState<boolean>(false);
  const [purchasedCoinsonboard, setPurchasedCoinsonboard] = useState<number>(100); // Здесь будет отображаться количество купленных монет.


  const handlePaymentSuccess = () => {
    setIsModalOpen(false); // Закрываем текущее модальное окно
    setIsThirdModalEmptyOpen(true); // Открываем новое модальное окно
  };

  const isMobilePlatform = () => {
    const userAgent = navigator.userAgent || navigator.vendor;
  
    // Проверяем Android
    if (/android/i.test(userAgent)) {
      return true;
    }
  
    // Проверяем iOS
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return true;
    }
  
    return false;
  };

  const isMobile = isMobilePlatform(); // Определяем мобильное устройство

  // Применяем стили: если не мобильное устройство, ширина 25% и центрируем
  const containerStyle = !isMobile ? { width: '25%', left: 'auto', right: 'auto' } : {};

  useEffect(() => {
      const userId = Cookies.get('userId');
      axios.post('https://golive.mobi/api/search/countrylist/', {
        id: userId,
      }, {
        headers: {
          'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        setCountries(response.data.list);
      })
      .catch(error => {
        console.error('Ошибка при получении списка стран:', error);
      });

  }, [isCountryModalOpen]);

  useEffect(() => {
    const preloadImage = (src: string) => {
      return new Promise<void>((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = () => resolve();
        img.onerror = () => reject(new Error(`Failed to load image: ${src}`));
      });
    };
  
    const loadCountriesWithFlags = async () => {
      const userId = Cookies.get('userId');
      if (userId) {
        try {
          const response = await axios.post(
            'https://golive.mobi/api/search/countrylist/',
            { id: userId },
            {
              headers: {
                'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
                'Content-Type': 'application/json',
              },
            }
          );
  
          const countriesData = response.data.list;
          const promises = countriesData.map((country: Country) => preloadImage(country.flag));
  
          // Ожидаем загрузку всех флагов
          await Promise.all(promises);
          setCountries(countriesData);
        } catch (error) {
          console.error('Ошибка при получении списка стран или загрузке флагов:', error);
        }
      }
    };
  
    loadCountriesWithFlags();
  }, []); // Этот эффект срабатывает один раз при монтировании компонента

  const handleAllUsersClick = () => {
    setSelectedFilter('all');
  };

  const handlePremiumUsersClick = () => {
    setSelectedFilter('premium');
  };

  const handleSwitchClick = () => {
    setIsSwitchOn(!isSwitchOn);
  };

  const handleGlobalButtonClick = () => {
    setIsCountryModalOpen(true);
};

  useEffect(() => {
    const userIdFromCookie = Cookies.get('userId');
    setUserId(userIdFromCookie || '');
  }, []);

  const handleGirlButtonClick = () => {
    setIsSuperMatchModalOpen(true);
  };

  const handleMatchClick = () => {
    // Проверяем права доступа к камере и микрофону по нажатию на Random Call
    navigator.permissions.query({ name: 'camera' as PermissionName })
      .then(permissionStatus => {
        if (permissionStatus.state === 'prompt') {
          setIsThirdModalOpen(true);
        } else {
          navigator.permissions.query({ name: 'microphone' as PermissionName })
            .then(micPermissionStatus => {
              if (micPermissionStatus.state === 'prompt') {
                setIsThirdModalOpen(true);
              } else {
                onMatchClick(); // Если все права предоставлены, переходим к следующему шагу
              }
            });
        }
      });
  };

  useEffect(() => {
    if (isModalOpen && !localStorage.getItem('DBB98') && welcomeTimer > 0) {
      const intervalId = setInterval(() => {
        setWelcomeTimer((prevTime) => prevTime - 1);
      }, 1000);
  
      if (welcomeTimer === 0) {
        setIsModalOpen(false);
        setIsSecondModalOpen(true);
      }
  
      return () => clearInterval(intervalId);
    }
  }, [isModalOpen, welcomeTimer]);

  useEffect(() => {
    if (isSecondModalOpen && secondTimer > 0) {
      const intervalId = setInterval(() => {
        setSecondTimer(prevTimer => prevTimer - 1);
      }, 1000);

      return () => clearInterval(intervalId);
    } else if (isSecondModalOpen && secondTimer === 0) {
      setIsSecondModalOpen(false);
      localStorage.setItem('DBB98', '9367'); // Сохранение в localStorage
    }
  }, [isSecondModalOpen, secondTimer]);

  const formatTimeWithHours = (time: number) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    return `${hours.toString().padStart(2, '0')} : ${minutes.toString().padStart(2, '0')} : ${seconds.toString().padStart(2, '0')}`;
  };

  const formatTime = (time: number) => {
    return time.toString().padStart(2, '0');
  };

  const handleClose = () => {
    localStorage.setItem('DBB98', '9367');
    setIsModalOpen(false);
    setIsSecondModalOpen(true);
  };

  useEffect(() => {
    if (!localStorage.getItem('DBB98')) {
      setIsModalOpen(true);  // Показываем окно только если нет значения в localStorage
    }
  }, []);

  const handlePaying = () => {
    setIsThirdModalEmptyOpen(false);
  };

  const handleContinueClick = () => {
    setIsSecondModalOpen(false); // Закрываем текущее окно
    setIsThirdModalEmptyOpen(true); // Открываем новое пустое окно
  };

  const handleAllowAll = () => {
    navigator.mediaDevices.getUserMedia({ video: true, audio: true })
      .then(stream => {
        // Handle successful permission granting
        console.log('Permissions granted');
        // Close the third modal and proceed with the application flow
        setIsThirdModalOpen(false);
        onMatchClick(); // Proceed to the Random Match after granting permissions
      })
      .catch(error => {
        // Handle errors or permission denial
        console.log('Permissions denied:', error);
        // You might want to show an error message or take some other action
      });
  };

  const handleBalancedCheckboxClick = () => {
    setSelectedOption('balanced');
    setSelectedCountry(null); // Сбрасываем выбор страны
  };
  
  const handleGlobalCheckboxClick = () => {
    setSelectedOption('global');
    setSelectedCountry(null); // Сбрасываем выбор страны
  };
  
  const handleCountryClick = (countryCode: string) => {
    setSelectedCountry(countryCode);
    setSelectedOption(null); // Сбрасываем выбор Balanced или Global
  };

  const handleUniversalContinueClick = () => {
    localStorage.setItem('DBB98', '9367');
    // Закрываем оба модальных окна, если они открыты
    setIsSecondModalOpen(false);
    setIsModalOpen(false);
  
    // Открываем пустое модальное окно
    setIsThirdModalEmptyOpen(true);
  };

  useEffect(() => {
    const userIdFromCookie = Cookies.get('userId');
    setUserId(userIdFromCookie || '');
  
    if (userIdFromCookie) {
      fetch('https://golive.mobi/api/coin/status/', {
        method: 'POST',
        headers: {
          'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: userIdFromCookie }),
      })
      .then(response => response.json())
      .then(data => {
        if (data.success === 1) {
          setBalance(data.balance);
        }
      })
      .catch(error => console.error('Error fetching balance:', error));
    }
  }, []);

// Пример логики для отображения FreeBadge с условием
const randomCallsValue = localStorage.getItem('randomCalls'); // Предположим, значение хранится в localStorage
const randomCalls = randomCallsValue ? parseInt(randomCallsValue, 10) : 0;

const FreeBadgeContent = randomCalls > 0 ? `Free ${randomCalls / 4}` : (
  <>
    5 <img src={CoinIcon} alt="Coin" style={{ width: '15px' }} />/min
  </>
);

const handleOpenMStore = () => {
  setModalStoreOpen(true);      // Открываем ModalStore
  console.log('setModalStoreOpen')
};

const handlePaymentSuccessStripe = () => {
  console.log('success pay in stripe')
  localStorage.setItem('DBB98', '9367');
  // Закрываем все модальные окна
  setIsModalOpen(false); 
  setIsSecondModalOpen(false);
  setModalStoreOpen(false);
  
  // Логика, если необходимо открыть какое-либо другое окно или действие после успешной оплаты
  setIsCenterModalOpen(true); // Пример: открываем новое модальное окно
};

const handlePaymentSuccessStripe2 = () => {
  localStorage.setItem('DBB98', '9367');
  // Закрываем все модальные окна
  setIsModalOpen(false); 
  setIsSecondModalOpen(false);
  setModalStoreOpen2(false);
  setModalStoreOpen(false);
  setIsThirdModalEmptyOpen(false);
  // Логика, если необходимо открыть какое-либо другое окно или действие после успешной оплаты
  setIsCenterModalOpen2(true); // Пример: открываем новое модальное окно
};

const handleCenterModalClose = () => {
  setIsCenterModalOpen(false); // Закрываем центрированное модальное окно
  setIsThirdModalEmptyOpen(true); // Открываем третье модальное окно
};

const handleCenterModalClose2 = () => {
  setIsCenterModalOpen2(false); // Закрываем центрированное модальное окно
};

const handlePayingCard1Click = () => {
  setPurchasedCoinsonboard(400); // Устанавливаем количество монет для первой карточки
  setModalStoreOpen2(true); // Открываем ModalStore
};

const handlePayingCard2Click = () => {
  setPurchasedCoinsonboard(900); // Устанавливаем количество монет для второй карточки
  setModalStoreOpen2(true); // Открываем ModalStore
};

  return (
    <>
    <div style={containerStyle}>
      <GradientBackground style={containerStyle} />
      <Container style={containerStyle}>

      <CoinsContainer>
    <CoinText>{balance !== null ? balance : 'Loading...'}</CoinText>
    <CoinImage src={CoinIcon} alt="Coins" />
  </CoinsContainer>

        <OnlineUsers>Currently 100<br /> people are online</OnlineUsers>
        <CentralText>Tap to start search</CentralText>
        <HandTapStartImage src={handtapstart} alt="Tap to start"  onClick={handleMatchClick} />
        <OptionButtonsContainer>
          <OptionButton onClick={handleGirlButtonClick}>
          <OptionIcon src={heartIcon} alt="Heart" />
  {selectedFilter === 'all' ? 'All users' : 'Premium'}
</OptionButton>
<OptionButton onClick={handleGlobalButtonClick}>
    <OptionIcon 
        src={
            selectedOption === 'balanced' || selectedOption === 'global'
                ? globeIcon
                : selectedCountry 
                    ? countries.find(country => country.code === selectedCountry)?.flag 
                    : globeIcon
        } 
        alt={
            selectedOption === 'balanced' 
                ? "Balanced"
                : selectedOption === 'global'
                    ? "Global"
                    : selectedCountry 
                        ? countries.find(country => country.code === selectedCountry)?.name 
                        : "Globe"
        }
        style={{ 
            width: selectedCountry ? '30px' : '24px', 
            height: selectedCountry ? '30px' : '24px', 
            borderRadius: selectedCountry ? '50%' : 'none' 
        }} 
    />
    {selectedOption === 'balanced' 
        ? 'Balanced' 
        : selectedOption === 'global' 
            ? 'Global'
            : selectedCountry 
                ? countries.find(country => country.code === selectedCountry)?.name 
                : 'Global'}
</OptionButton>
        </OptionButtonsContainer>
        <MatchButtonContainer>
          <MatchButton onClick={handleMatchClick}>
            Random Match
            <FreeBadge>Free</FreeBadge>
          </MatchButton>
        </MatchButtonContainer>
        <NavBar>
          <NavItem src={storeIcon} alt="Store" onClick={onStoreClick} />
          <NavItem src={listIcon} alt="List" onClick={onListClick} />
          <NavItem src={videochatIcon} alt="Video Chat" onClick={() => { /* Добавьте логику для открытия видео-чата */ }} />
          <NavItem src={textchatIcon} alt="Text Chat" onClick={onChatsClick} />
        </NavBar>
      </Container>
  
      {isModalOpen && (
        <ModalOverlay style={containerStyle}>
          <ModalContent>
            <HeaderContainer>
              <CloseButton 
                src={popupclosebutton} 
                alt="Close" 
                onClick={handleClose} 
              />
              <WelcomeText>Welcome bonus</WelcomeText>
            </HeaderContainer>
            <CountdownContainer>
              <CountdownText>Countdown</CountdownText>
              <Timer>
                <Time>00</Time>
                <Separator>:</Separator>
                <Time>00</Time>
                <Separator>:</Separator>
               <Time isHighlighted>{formatTime(welcomeTimer)}</Time>
              </Timer>
            </CountdownContainer>
            <StartVipImage src={startvip} alt="Start VIP" />
            <ContinueVipButton onClick={handleOpenMStore}>Continue</ContinueVipButton>

         {/*   <Stripe onPaymentSuccess={handlePaymentSuccess} /> */}

            <AgreementText>
  By purchasing you agree to our 
  <a href="https://deus.mobi/golive/terms" target="_blank" rel="noopener noreferrer"> Terms of Service</a> and 
  <a href="https://deus.mobi/golive/privacy" target="_blank" rel="noopener noreferrer"> Privacy Policy</a>.
</AgreementText>
          </ModalContent>
        </ModalOverlay>
      )}
  
      {isSecondModalOpen && (
        <CenteredModalOverlay style={containerStyle}>
          <ModalContentSecond>
            <HeaderContainer>
              <CloseButton 
                src={popupclosebutton} 
                alt="Close" 
                onClick={() => setIsSecondModalOpen(false)} 
              />
              <img 
                src={lastchance} 
                alt="Last Chance" 
                style={{ marginTop: '5px', width: '200px', height: 'auto', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
              />
            </HeaderContainer>
            <CountdownContainer>
              <CountdownText>Countdown</CountdownText>
              <Timer>
                <Time isHighlighted>{formatTimeWithHours(secondTimer)}</Time>
              </Timer>
            </CountdownContainer>
            <StartVipImage src={freetrialsecond} alt="Free trial" />
            <ContinueVipButton onClick={handleOpenMStore}>Continue</ContinueVipButton>
            <AgreementText>
  By purchasing you agree to our 
  <a href="https://deus.mobi/golive/terms" target="_blank" rel="noopener noreferrer"> Terms of Service</a> and 
  <a href="https://deus.mobi/golive/privacy" target="_blank" rel="noopener noreferrer"> Privacy Policy</a>.
</AgreementText>
          </ModalContentSecond>
        </CenteredModalOverlay>
      )}
  
  {isThirdModalOpen && (
  <ModalOverlay style={containerStyle}>
    <ModalContent style={{ position: 'relative', padding: '30px 0px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>
      {/* Изображение "Allow Services" */}
      <img 
        src={allowservices2} 
        alt="Allow Services" 
        style={{
          width: '80%',
          height: 'auto',
          display: 'block',
          marginBottom: '20px' // Отступ снизу
        }}
      />
      
      {/* Кнопка "Allow All" */}
      <button
        onClick={handleAllowAll}
        style={{
          backgroundColor: '#FCD900',
          color: 'black',
          fontSize: '20px',
          fontWeight: 'bold',
          padding: '15px 30px',
          border: 'none',
          borderRadius: '50px',
          cursor: 'pointer',
          width: '35vh',
          marginTop: '20px' // Отступ сверху
        }}
      >
        Allow all
      </button>
    </ModalContent>
  </ModalOverlay>
)}

{isCenterModalOpen && (
  <CenterModal 
    onClose={handleCenterModalClose}
    purchasedCoins={purchasedCoinsonboard} // Передаем количество купленных монет
  />
)}

{isCenterModalOpen2 && (
  <CenterModal 
    onClose={handleCenterModalClose2}
    purchasedCoins={purchasedCoinsonboard} // Передаем количество купленных монет
  />
)}

{isThirdModalEmptyOpen && (
  <CenteredModalOverlay style={containerStyle}>
    <ModalContentSecond style={{ height: 'auto' }}>
      <HeaderContainer style={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'flex-start', 
        paddingTop: '20px', // Добавлен отступ сверху для пространства
        position: 'relative' // Положение для абсолютного позиционирования кнопки закрытия
      }}>
        <CloseButton 
          src={popupclosebutton} 
          alt="Close" 
          onClick={handlePaying}
          style={{
            position: 'absolute',
            top: '20px', // Выровнено с верхом контейнера
            left: '20px', // Отступ слева
            width: '36px', // Размер кнопки
            height: '36px', // Размер кнопки
            cursor: 'pointer',
            zIndex: 1000 // Убедитесь, что кнопка закрытия находится выше всех элементов
          }}
        />
        <img 
          src={manycoins} 
          alt="Many Coins" 
          style={{ 
            width: '150px', // Регулируемый размер изображения
            height: 'auto', // Сохранение пропорций
            marginBottom: '20px' // Отступ снизу
          }} 
        />
        <img 
          src={paycongratulations} 
          alt="Congratulations" 
          style={{ 
            width: '350px', // Регулируемый размер изображения
            height: 'auto', // Сохранение пропорций
            marginBottom: '20px' // Отступ снизу
          }} 
        />
        <div 
          style={{
            textAlign: 'center',
            color: 'white',
            fontSize: '24px',
            fontWeight: 'bold',
            marginBottom: '20px', // Отступ снизу для текста перед карточками
          }}
        >
          <p style={{ margin: 0 }}>
            You have bought <img src={CoinIcon} alt="Coin" style={{ width: '20px', verticalAlign: 'middle' }} /> 100
          </p>
          <p style={{ marginTop: '10px' }}>
            Get even more coins <br />with a <span style={{ color: '#FFD700' }}>special discount</span> 👇
          </p>
        </div>
        
        <div 
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '20px' // Расстояние между карточками
          }}
        >
          <img 
            src={payingcard1} 
            alt="Paying Card 1" 
            style={{ 
              width: '150px', // Регулируемый размер изображения
              height: 'auto', // Сохранение пропорций
            }} 
            onClick={handlePayingCard1Click}
          />
          <img 
            src={payingcard2} 
            alt="Paying Card 2" 
            style={{ 
              width: '150px', // Регулируемый размер изображения
              height: 'auto', // Сохранение пропорций
            }} 
            onClick={handlePayingCard2Click}
          />
        </div>
      </HeaderContainer>
    </ModalContentSecond>
  </CenteredModalOverlay>
)}
  
  <ModalSuperMatch 
  isOpen={isSuperMatchModalOpen}
  onClose={() => setIsSuperMatchModalOpen(false)}
  variant="primary"
>
  <div style={{ padding: '30px', paddingLeft: '30px', textAlign: 'left' }}>
    <h1 style={{ fontSize: '40px', fontWeight: 'bold', color: '#fff', margin: 0 }}>Match filter</h1>
    <h2 style={{ fontSize: '20px', fontWeight: 'bold', color: '#fff', marginTop: '20px', marginBottom: 0 }}>
      Match with
    </h2>
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px', gap: '20px' }}>
      <img
        src={selectedFilter === 'all' ? alluserson : allusersoff}
        alt="All users"
        style={{ width: '50%', cursor: 'pointer' }}
        onClick={handleAllUsersClick}
      />
      <img
        src={selectedFilter === 'premium' ? premiumuserson : premiumusersoff}
        alt="Premium users"
        style={{ width: '50%', cursor: 'pointer' }}
        onClick={handlePremiumUsersClick}
      />
    </div>

    {/* Добавляем текст Super Match */}
    <div style={{ marginTop: '30px' }}>
      <h1 style={{ fontSize: '24px', fontWeight: 'bold', color: '#fff', margin: 0 }}>Super Match</h1>
    </div>

    {/* Добавляем иконку S, текст описания Super Match и иконку монеты с количеством */}
    <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
      <img src={strange_s} alt="S" style={{ width: '40px', marginRight: '10px' }} />
      <p style={{ fontSize: '14px', color: '#fff', margin: 0, flexGrow: 1 }}>
        The Super Match filter helps you find more compatible matches
      </p>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img src={CoinIcon} alt="Coin" style={{ width: '24px', marginRight: '5px' }} />
        <span style={{ fontSize: '20px', color: '#fff' }}>20</span>
      </div>
      <img
        src={isSwitchOn ? filterswitchon : filterswitchoff}
        alt="Switch"
        style={{ width: '50px', marginLeft: '10px', cursor: 'pointer' }}
        onClick={handleSwitchClick}
      />
    </div>

    {/* Добавляем кнопку Go Match */}
    <div style={{ marginTop: '40px', textAlign: 'center' }}>
      <button
        onClick={() => {
          handleMatchClick();
          setIsSuperMatchModalOpen(false); // Закрываем модальное окно после нажатия
        }}
        style={{
          backgroundColor: '#FCD900',
          color: 'black',
          fontSize: '20px',
          fontWeight: 'bold',
          padding: '15px 0',
          border: 'none',
          borderRadius: '50px',
          width: '100%',
          cursor: 'pointer',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        }}
      >
        Go Match
      </button>
    </div>
  </div>
</ModalSuperMatch>

{isModalStoreOpen && (
  <ModalStore 
    onClose={() => setModalStoreOpen(false)}
    balance={balance || 0}
    amount={100}  // Устанавливаем значение amount в 100
    paymentChargeId={1} // Передаем нужный ID плана или идентификатор оплаты
    onPaymentSuccessAndClose={handlePaymentSuccessStripe}  // Закрываем модальное окно после успешной оплаты
  />
)}

{isModalStoreOpen2 && (
  <ModalStore 
    onClose={() => setModalStoreOpen2(false)}
    balance={balance || 0}
    amount={purchasedCoinsonboard}  // Устанавливаем значение amount
    paymentChargeId={1}  // Передаем нужный ID плана или идентификатор оплаты
    onPaymentSuccessAndClose={handlePaymentSuccessStripe2}  // Закрываем модальное окно после успешной оплаты
  />
)}

{isCountryModalOpen && (
 <ModalCountry 
 isOpen={isCountryModalOpen}
 onClose={() => setIsCountryModalOpen(false)}
>
 <div style={{ padding: '30px', color: '#fff', display: 'flex', flexDirection: 'column', height: '100%', background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.7) 100%)' }}>
     <h1 style={{ fontSize: '30px', fontWeight: 'bold', color: '#fff', margin: 0, marginTop: '10px' }}>Country filter</h1>
     <div style={{ flexGrow: 1, marginTop: '20px', maxHeight: '400px', overflowY: 'auto', position: 'relative', borderBottomRightRadius: '25px', borderBottomLeftRadius: '25px' }}>
         {/* Добавляем кнопки balanced и global */}
         <div
             style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginBottom: '20px' }}
             onClick={() => handleBalancedCheckboxClick()}
         >
             <img
                 src={selectedOption === 'balanced' ? checkon : checkoff}
                 alt="Balanced"
                 style={{ marginRight: '10px', width: '18px', height: '18px' }}
             />
             <span style={{ fontSize: '20px', color: '#fff' }}>Balanced</span>
         </div>
         <div
             style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginBottom: '20px' }}
             onClick={() => handleGlobalCheckboxClick()}
         >
             <img
                 src={selectedOption === 'global' ? checkon : checkoff}
                 alt="Global"
                 style={{ marginRight: '10px', width: '18px', height: '18px' }}
             />
             <span style={{ fontSize: '20px', color: '#fff' }}>Global</span>
         </div>

         {countries.map((country: Country) => (
             <div
                 key={country.code}
                 style={{ 
                     display: 'flex', 
                     alignItems: 'center', 
                     cursor: 'pointer', 
                     marginBottom: '20px', 
                     justifyContent: 'space-between' // для выравнивания монет справа
                 }}
                 onClick={() => handleCountryClick(country.code)}
             >
                 <div style={{ display: 'flex', alignItems: 'center' }}>
                     <img
                         src={selectedCountry === country.code ? checkon : checkoff}
                         alt={country.name}
                         style={{ marginRight: '10px', width: '18px', height: '18px' }}
                     />
                     <img
                         src={country.flag}
                         alt={`${country.name} flag`}
                         style={{ 
                             width: '30px', // увеличиваем размер флагов
                             height: '30px', 
                             borderRadius: '50%', // делаем флаги круглыми
                             marginRight: '10px',
                             objectFit: 'cover', // чтобы изображение флага не деформировалось
                             display: 'inline-block', // для выравнивания по центру
                             verticalAlign: 'middle' // для центровки по вертикали
                         }}
                     />
                     <span style={{ fontSize: '20px', color: '#fff', verticalAlign: 'middle' }}>{country.name}</span>
                 </div>
                 <div style={{ display: 'flex', alignItems: 'center' }}>
                     <img
                         src={CoinIcon} // путь к иконке монеты
                         alt="Coin"
                         style={{ width: '20px', height: '20px', marginRight: '5px' }}
                     />
                     <span style={{ fontSize: '20px', color: '#fff', lineHeight: '20px', fontWeight: 'bold' }}>20</span>
                 </div>
             </div>
         ))}
         {/* Добавляем замыливание */}
         
         <button
             onClick={() => {
                 handleMatchClick();
                 setIsCountryModalOpen(false);
             }}
             style={{
                 position: 'sticky',
                 bottom: 0,
                 backgroundColor: '#FCD900',
                 color: 'black',
                 fontSize: '20px',
                 fontWeight: 'bold',
                 padding: '15px 0',
                 border: 'none',
                 borderRadius: '50px',
                 width: '100%',
                 cursor: 'pointer',
                 boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                 zIndex: 10, // Чтобы кнопка была поверх списка
             }}
         >
             Go Match
         </button>
         
     </div>
 </div>
</ModalCountry>
)}</div>
    </>
  );
};

export default RandomCall;