import React, { useState } from 'react';
import styled from 'styled-components';
import f_arrowdown from '../res_land/f_arrowdown.png';
import f_arrowright from '../res_land/f_arrowright.png';

const FAQContainer = styled.div`
  width: 90%;
  max-width: 600px;
  border-radius: 8px;
  margin-top: 20px;
  padding: 20px;
`;

const FAQTitle = styled.h2`
  font-size: 28px;
  margin-bottom: 28px;
`;

const FAQItem = styled.div`
  margin-bottom: 15px;
  background-color: #EFEEF5; /* Цвет фона */
  border-radius: 10px; /* Закругленные края */
  padding: 15px; /* Внутренние отступы */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Легкая тень */
`;

const FAQQuestion = styled.div`
  font-weight: bold;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const FAQAnswer = styled.div<{ isOpen: boolean }>`
  max-height: ${({ isOpen }) => (isOpen ? '500px' : '0')};
  overflow: hidden;
  transition: max-height 0.3s ease;
  font-size: 16px;
  color: #666;
  margin-top: ${({ isOpen }) => (isOpen ? '10px' : '0')};
`;

const ArrowImage = styled.img`
  width: 20px;
  height: 20px;
  transition: transform 0.3s ease;
`;

const FAQs: React.FC = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleFAQ = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqData = [
    {
      question: 'What is Golive?',
      answer:
        'Golive is a cutting-edge video chat app that enables users to connect with strangers from around the globe through random video calls. It offers a unique opportunity to meet new people, forge new friendships, and participate in meaningful conversations.',
    },
    {
      question: 'Are There Any Other Sites Like Omegle?',
      answer:
        'Yes, there are several sites similar to Omegle, each offering distinct features and benefits. Golive emerges as a leading Omegle alternative app, known for its exceptional user experience and strong safety features. Your ideal platform will depend on your individual needs and preferences.',
    },
    {
      question: 'What Makes Golive Unique in Social Networking?',
      answer:
        'Golive distinguishes itself by focusing on genuine face-to-face interactions via real-time 1-on-1 video chats. Unlike conventional text-based conversations, this method enables users to form immediate and meaningful connections. Golive’s emphasis on spontaneous video chats creates a dynamic and authentic social networking experience.',
    },
    {
      question: 'Can I Use Golive Anytime and Anywhere?',
      answer:
        'Golive provides the ultimate flexibility, allowing users to connect with new people via its mobile app or website. Whether you’re on the move or at your computer, Golive’s adaptability ensures a seamless experience. Its wide accessibility and emphasis on spontaneous video chats enable you to interact freely with others, anytime and anywhere, all while prioritizing your safety.',
    },
    {
      question: 'Can I Use Golive for Free?',
      answer:
        'Yes, you can! Golive is available for free download on the Google Play Store, allowing you to access essential features like random chat and video calls at no cost. While core features are free for all users, optional in-app purchases are available for premium features. These premium options are designed to enhance your social experience and offer additional ways to connect and interact on Golive.',
    },
  ];

  return (
    <FAQContainer>
      <FAQTitle>FAQs</FAQTitle>
      {faqData.map((faq, index) => (
        <FAQItem key={index}>
          <FAQQuestion onClick={() => toggleFAQ(index)}>
            {faq.question}
            <ArrowImage
              src={openIndex === index ? f_arrowdown : f_arrowright}
              alt="arrow"
            />
          </FAQQuestion>
          <FAQAnswer isOpen={openIndex === index}>{faq.answer}</FAQAnswer>
        </FAQItem>
      ))}
    </FAQContainer>
  );
};

export default FAQs;